@import './normalize.css';
@import '../assets/fonts/icomoon/style.css';

:root {
  --banner-link-bg: #000;
  --banner-link-color: #fff;
  --banner-link-border-color: #fff;
  --banner-link-hover-bg: #fff;
  --banner-link-hover-color: #000;
  --banner-link-hover-border-color: #000;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: Montserrat;
  font-size: 16px;
}

/* Common */
.w-100, .s-100 {
  width: 100%;
}
.w-50, .s-50 {
  width: 50%;
}
.h-100, .s-100 {
  height: 100%;
}
.vw-100 {
  width: 100vw;
}
.max-vw-100 {
  max-width: 100vw;
}
.page-section {
  min-height: 100vh;
  overflow-x: hidden;
}
.txt-center {
  text-align: center;
}
.bg-black {
    background: black;
}
.txt-white {
    color: white;
}
.p-y-6 {
    padding-top: 36px;
    padding-bottom: 36px;
}
.p-x-3 {
    padding-left: 18px;
    padding-right: 18px;
}
a {
    color: #00adff;
}

/* Banner */
.banner {
  background: url(/assets/images/banner_1.jpg) center center no-repeat;
  background-size: cover;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.banner::after {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  display: block;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.banner-content {
  font-size: 2rem;
  padding: 0 4rem;
  line-height: 3rem;
  z-index: 2;
}

.banner-phones {
  list-style: none;
  padding: 0;
  width: 100%;
}

.banner-phones li {
  display: inline-block;
  width: 100%;
}

.banner-phones .qr-phone {
  display: none;
  margin: 2rem 0 0 0;
  width: 100%;
}

.banner-link {
  background: var(--banner-link-bg);
  border: 1px solid var(--banner-link-border-color);
  color: var(--banner-link-color);
  display: inline-block;
  font-size: 0.7em;
  text-align: center;
  text-decoration: none;
  transition: background 0.5s ease-in,
              border-color 0.3s linear,
              color 0.3s linear;
  line-height: 2.75em;
  vertical-align: middle;
  height: 2.75em;
  width: 100%;
}

.banner-link:hover {
  background: var(--banner-link-hover-bg);
  border-color: var(--banner-link-hover-border-color);
  color: var(--banner-link-hover-color);
}

.banner-link .icon {
  margin: 0 10px 0 0;
}

.banner-logo {
  width: 75%;
}

@media screen and (min-width: 1300px) {
  .banner-logo {
    width: 360px;
    text-align: center;
  }
}

@media screen and (min-width: 778px) {
  .banner-phones li {
    width: min-content;
  }

  .banner-phones .qr-phone {
    display: initial;
  }

  .banner-link {
    width: 9.2em;
  }
}

/* Safety */
.safety {
  background: #1e5995;
  padding: 2rem 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.safety-title {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  flex: 1;
  margin: 0 0 1rem;
}
.safety-post {
  flex: 2;
}
@media screen and (min-width: 1024px) {
  .safety {
    flex-direction: row;
  }
  .safety-title {
    font-size: 3.5rem;
  }
}

/* Footer */
.footer {
  background: #000;
  color: #fff;
  font-size: 0.9rem;
  padding: 5px;
  text-align: center;
}
.footer a {
  color: #fff;
  margin: 0 1rem 0 0;
}


/* Scroll Down Arrow */
.scroll-down-arrow {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=);
	background-size: contain;
	background-repeat: no-repeat;
  cursor:pointer;
	height: 60px;
	width: 80px;
	margin: 0px 0 0 -40px;
	line-height: 60px;
	position: absolute;
	left: 50%;
	bottom: 25px;
	color: #FFF;
	text-align: center;
	font-size: 70px;
	z-index: 100;
	text-decoration: none;
	text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);

	-webkit-animation: fade_move_down 2s ease-in-out infinite;
	-moz-animation:    fade_move_down 2s ease-in-out infinite;
	animation:         fade_move_down 2s ease-in-out infinite;
}

/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
  0%   { -webkit-transform:translate(0,-20px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -webkit-transform:translate(0,20px); opacity: 0; }
}
@-moz-keyframes fade_move_down {
  0%   { -moz-transform:translate(0,-20px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -moz-transform:translate(0,20px); opacity: 0; }
}
@keyframes fade_move_down {
  0%   { transform:translate(0,-20px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { transform:translate(0,20px); opacity: 0; }
}


/* Map */
.map {
  height: 300px;
}
